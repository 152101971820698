import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Conditionally Creating Terraform Resources",
  "date": "2021-09-17T13:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/terraform-conditional-resources/",
  "category": "Software",
  "tags": ["til", "terraform", "cloud"],
  "description": "You can conditionally create a Terraform resource using the 'count' meta-argument and the ternary operator."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You can conditionally create a Terraform infrastructure object by combining the ternary operator with the `}<inlineCode parentName="p">{`count`}</inlineCode>{` meta-argument.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`count`}</inlineCode>{` meta-argument lets you define multiple similar objects using a single block. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`resource "aws_instance" "server" {
  count = 4 # create four similar EC2 instances
  ami           = "ami-a1b2c3d4"
  instance_type = "t2.micro"
  tags = {
    Name = "Server \${count.index}"
  }
}
`}</code></pre>
    <p>{`We can therefore define a resource to be create with a count of `}<inlineCode parentName="p">{`0`}</inlineCode>{` (don't create the resource at all) or `}<inlineCode parentName="p">{`1`}</inlineCode>{` based on some condition. Here's an example of how we'd create some resource only if the variable `}<inlineCode parentName="p">{`env`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`"prod"`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`resource "aws_instance" "server" {
  count = var.env == "prod" ? 1 : 0
  ami           = "ami-a1b2c3d4"
  instance_type = "t2.micro"
}
`}</code></pre>
    <p>{`Further reading: `}<a parentName="p" {...{
        "href": "https://www.terraform.io/docs/language/meta-arguments/count.html"
      }}>{`Terraform documentation`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      